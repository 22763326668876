import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { chartApi } from "../../api/api.chart";

const initialState = {
    byType: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    points: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    pointsSummary: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    incidents: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    incidentsSummary: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byMonth: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byTerm: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    allByMonth: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    allByTerm: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byClass: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byStaff: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byTutorGroup: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byEthnicity: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byEthnicityRate: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    bySubject: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byStudentType: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byWeek: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    attendance: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    byDayOFWeek: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
    attendancePc: {
        processing: "",
        data: [],
        status: "idle",
        error: "",
    },
};

export const getByType = createAsyncThunk("charts/getByType", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByType(payload.include, payload.exclude);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.category);
        return { labels: returnLabels, data: returnData };
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});
export const getBySubject = createAsyncThunk("charts/getBySubject", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getBySubject(payload.include, payload.exclude);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.subjectname);
        return { labels: returnLabels, data: returnData };
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByClass = createAsyncThunk("charts/getByClass", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByClass(payload.include, payload.exclude);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.classcode);
        return { labels: returnLabels, data: returnData };
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByStaff = createAsyncThunk("charts/getByStaff", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByStaff(payload.include, payload.exclude);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.staff_staffcode);
        return { labels: returnLabels, data: returnData };
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByStudentType = createAsyncThunk("charts/getByStudentType", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByStudentType(payload.include, payload.exclude);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.value);
        const returnLabels = apiResponse.data.map((item) => item.label);
        return [{ name: "Incidents/Awards", labels: returnLabels, values: returnData }];
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

/* 
export const getByTutor = createAsyncThunk("charts/getByTutor", async (payload, thunkAPI) => {

    const apiResponse = await chartApi.getByTutor(payload);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.student_tutorgroup);
        return [{name: "Conduct", labels: returnLabels, values: returnData }];
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
}); */

export const getByTutor = createAsyncThunk("charts/getByTutor", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByTutor(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = [];
            const values = [];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                labels.push(apiResponse.data[i].data[j].student_tutorgroup);
                values.push(apiResponse.data[i].data[j].count);
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByEthnicity = createAsyncThunk("charts/getByEthnicity", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByEthnicity(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = [];
            const values = [];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                labels.push(apiResponse.data[i].data[j].student_ethnicity);
                values.push(apiResponse.data[i].data[j].count);
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByEthnicityRate = createAsyncThunk("charts/getByEthnicityRate", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByEthnicityRate(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = [];
            const values = [];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                labels.push(apiResponse.data[i].data[j].student_ethnicity);
                values.push(apiResponse.data[i].data[j].rate);
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getPoints = createAsyncThunk("charts/getPoints", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getPoints(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});
export const getIncidents = createAsyncThunk("charts/getIncidents", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getIncidents(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getAttendance = createAsyncThunk("charts/getAttendance", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getAttendance(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getPointsSummary = createAsyncThunk("charts/getPointsSummary", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getPointsSummary(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});
export const getIncidentsSummary = createAsyncThunk("charts/getIncidentsSummary", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getIncidentsSummary(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getConductByMonth = createAsyncThunk("charts/getConductByMonth", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getConductByMonth(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = ["September", "October", "November", "December", "January", "February", "March", "April", "May", "June", "July"];
            const values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                const index = labels.indexOf(apiResponse.data[i].data[j].label);
                if (index === -1) {
                    continue;
                }
                values[index] = apiResponse.data[i].data[j].value;
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByWeek = createAsyncThunk("charts/getByWeek", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getByWeek(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = [];
            const values = [];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                labels.push(new Date(apiResponse.data[i].data[j].label).toLocaleDateString());
                values.push(apiResponse.data[i].data[j].value);
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getBehaviourAndAchievementByWeek = createAsyncThunk("charts/getBehaviourAndAchievementByWeek", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getAllByWeek(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = [];
            const values = [];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                labels.push(new Date(apiResponse.data[i].data[j].label).toLocaleDateString());
                values.push(apiResponse.data[i].data[j].value);
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getConductByTerm = createAsyncThunk("charts/getConductByTerm", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getConductByTerm(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = ["Term 1", "Term 2", "Term 3", "Term 4", "Term 5", "Term 6"];
            const values = [0, 0, 0, 0, 0, 0];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                const index = labels.indexOf(apiResponse.data[i].data[j].label);
                if (index === -1) {
                    continue;
                }
                values[index] = apiResponse.data[i].data[j].value;
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getBehaviourAndAchievementByMonth = createAsyncThunk("charts/getBehaviourAndAchievementByMonth", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getBehaviourAndAchievementByMonth(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = ["September", "October", "November", "December", "January", "February", "March", "April", "May", "June", "July"];
            const values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                const index = labels.indexOf(apiResponse.data[i].data[j].label);
                if (index === -1) {
                    continue;
                }
                values[index] = apiResponse.data[i].data[j].value;
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getBehaviourAndAchievementByTerm = createAsyncThunk("charts/getBehaviourAndAchievementByTerm", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getBehaviourAndAchievementByTerm(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = ["Term 1", "Term 2", "Term 3", "Term 4", "Term 5", "Term 6"];
            const values = [0, 0, 0, 0, 0, 0];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                const index = labels.indexOf(apiResponse.data[i].data[j].label);
                if (index === -1) {
                    continue;
                }
                values[index] = apiResponse.data[i].data[j].value;
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});


export const getBehaviourAndAchievementByDayOfWeek = createAsyncThunk("charts/getBehaviourAndAchievementByDayOfWeek", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getBehaviourAndAchievementByDayOfWeek(payload.include, payload.exclude);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            const values = [0, 0, 0, 0, 0, 0, 0];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                const index = labels.indexOf(apiResponse.data[i].data[j].label);
                if (index === -1) {
                    continue;
                }
                values[index] = apiResponse.data[i].data[j].value;
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getAttendancePc = createAsyncThunk("charts/getAttendancePc", async (payload, thunkAPI) => {
    const apiResponse = await chartApi.getAttendancePc(payload.include, payload.exclude);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});


export const chartsSlice = createSlice({
    name: "charts",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // Get by type
            .addCase(getByType.pending, (state, action) => {
                state.byType.processing = action.meta.requestId;
                state.byType.status = "loading";
            })
            .addCase(getByType.fulfilled, (state, action) => {
                if (state.byType.processing === action.meta.requestId) {
                    state.byType.processing = "";
                    state.byType.status = "idle";
                    state.byType.data = action.payload;
                }
            })
            .addCase(getByType.rejected, (state, action) => {
                if (state.byType.processing === action.meta.requestId) {
                    state.byType.processing = "";
                    state.byType.status = "error";
                    state.byType.error = action.payload;
                    state.byType.data = [];
                }
            })

            // Get points
            .addCase(getPoints.pending, (state, action) => {
                state.points.processing = action.meta.requestId;
                state.points.status = "loading";
            })
            .addCase(getPoints.fulfilled, (state, action) => {
                if (state.points.processing === action.meta.requestId) {
                    state.points.processing = "";
                    state.points.status = "idle";
                    state.points.data = action.payload;
                }
            })
            .addCase(getPoints.rejected, (state, action) => {
                if (state.points.processing === action.meta.requestId) {
                    state.points.processing = "";
                    state.points.status = "error";
                    state.points.error = action.payload;
                    state.points.data = [];
                }
            })

            // Get points summary
            .addCase(getPointsSummary.pending, (state, action) => {
                state.pointsSummary.processing = action.meta.requestId;
                state.pointsSummary.status = "loading";
            })
            .addCase(getPointsSummary.fulfilled, (state, action) => {
                if (state.pointsSummary.processing === action.meta.requestId) {
                    state.pointsSummary.processing = "";
                    state.pointsSummary.status = "idle";
                    state.pointsSummary.data = action.payload;
                }
            })
            .addCase(getPointsSummary.rejected, (state, action) => {
                if (state.pointsSummary.processing === action.meta.requestId) {
                    state.pointsSummary.processing = "";
                    state.pointsSummary.status = "error";
                    state.pointsSummary.error = action.payload;
                    state.pointsSummary.data = [];
                }
            })

            // Get conduct by month
            .addCase(getConductByMonth.pending, (state, action) => {
                state.byMonth.processing = action.meta.requestId;
                state.byMonth.status = "loading";
            })
            .addCase(getConductByMonth.fulfilled, (state, action) => {
                if (state.byMonth.processing === action.meta.requestId) {
                    state.byMonth.processing = "";
                    state.byMonth.status = "idle";
                    state.byMonth.data = action.payload;
                }
            })
            .addCase(getConductByMonth.rejected, (state, action) => {
                if (state.byMonth.processing === action.meta.requestId) {
                    state.byMonth.processing = "";
                    state.byMonth.status = "error";
                    state.byMonth.error = action.payload;
                    state.byMonth.data = [];
                }
            })

            // Get behaviour and achievement by month
            .addCase(getBehaviourAndAchievementByMonth.pending, (state, action) => {
                state.allByMonth.processing = action.meta.requestId;
                state.allByMonth.status = "loading";
            })
            .addCase(getBehaviourAndAchievementByMonth.fulfilled, (state, action) => {
                if (state.allByMonth.processing === action.meta.requestId) {
                    state.allByMonth.processing = "";
                    state.allByMonth.status = "idle";
                    state.allByMonth.data = action.payload;
                }
            })
            .addCase(getBehaviourAndAchievementByMonth.rejected, (state, action) => {
                if (state.allByMonth.processing === action.meta.requestId) {
                    state.allByMonth.processing = "";
                    state.allByMonth.status = "error";
                    state.allByMonth.error = action.payload;
                    state.allByMonth.data = [];
                }
            })

            // Get behaviour and achievement by term
            .addCase(getBehaviourAndAchievementByTerm.pending, (state, action) => {
                state.allByTerm.processing = action.meta.requestId;
                state.allByTerm.status = "loading";
            })
            .addCase(getBehaviourAndAchievementByTerm.fulfilled, (state, action) => {
                if (state.allByTerm.processing === action.meta.requestId) {
                    state.allByTerm.processing = "";
                    state.allByTerm.status = "idle";
                    state.allByTerm.data = action.payload;
                }
            })
            .addCase(getBehaviourAndAchievementByTerm.rejected, (state, action) => {
                if (state.allByTerm.processing === action.meta.requestId) {
                    state.allByTerm.processing = "";
                    state.allByTerm.status = "error";
                    state.allByTerm.error = action.payload;
                    state.allByTerm.data = [];
                }
            })

            // Get conduct by term
            .addCase(getConductByTerm.pending, (state, action) => {
                state.byTerm.processing = action.meta.requestId;
                state.byTerm.status = "loading";
            })
            .addCase(getConductByTerm.fulfilled, (state, action) => {
                if (state.byTerm.processing === action.meta.requestId) {
                    state.byTerm.processing = "";
                    state.byTerm.status = "idle";
                    state.byTerm.data = action.payload;
                }
            })
            .addCase(getConductByTerm.rejected, (state, action) => {
                if (state.byTerm.processing === action.meta.requestId) {
                    state.byTerm.processing = "";
                    state.byTerm.status = "error";
                    state.byTerm.error = action.payload;
                    state.byTerm.data = [];
                }
            })

            // Get incidents
            .addCase(getIncidents.pending, (state, action) => {
                state.incidents.processing = action.meta.requestId;
                state.incidents.status = "loading";
            })
            .addCase(getIncidents.fulfilled, (state, action) => {
                if (state.incidents.processing === action.meta.requestId) {
                    state.incidents.processing = "";
                    state.incidents.status = "idle";
                    state.incidents.data = action.payload;
                }
            })
            .addCase(getIncidents.rejected, (state, action) => {
                if (state.incidents.processing === action.meta.requestId) {
                    state.incidents.processing = "";
                    state.incidents.status = "error";
                    state.incidents.error = action.payload;
                    state.incidents.data = [];
                }
            })

            // Get incidents summary
            .addCase(getIncidentsSummary.pending, (state, action) => {
                state.incidentsSummary.processing = action.meta.requestId;
                state.incidentsSummary.status = "loading";
            })
            .addCase(getIncidentsSummary.fulfilled, (state, action) => {
                if (state.incidentsSummary.processing === action.meta.requestId) {
                    state.incidentsSummary.processing = "";
                    state.incidentsSummary.status = "idle";
                    state.incidentsSummary.data = action.payload;
                }
            })
            .addCase(getIncidentsSummary.rejected, (state, action) => {
                if (state.incidentsSummary.processing === action.meta.requestId) {
                    state.incidentsSummary.processing = "";
                    state.incidentsSummary.status = "error";
                    state.incidentsSummary.error = action.payload;
                    state.incidentsSummary.data = [];
                }
            })

            // Get by subject
            .addCase(getBySubject.pending, (state, action) => {
                state.bySubject.processing = action.meta.requestId;
                state.bySubject.status = "loading";
            })
            .addCase(getBySubject.fulfilled, (state, action) => {
                if (state.bySubject.processing === action.meta.requestId) {
                    state.bySubject.processing = "";
                    state.bySubject.status = "idle";
                    state.bySubject.data = action.payload;
                }
            })
            .addCase(getBySubject.rejected, (state, action) => {
                if (state.bySubject.processing === action.meta.requestId) {
                    state.bySubject.processing = "";
                    state.bySubject.status = "error";
                    state.bySubject.error = action.payload;
                    state.bySubject.data = [];
                }
            })

            // Get by class
            .addCase(getByClass.pending, (state, action) => {
                state.byClass.processing = action.meta.requestId;
                state.byClass.status = "loading";
            })
            .addCase(getByClass.fulfilled, (state, action) => {
                if (state.byClass.processing === action.meta.requestId) {
                    state.byClass.processing = "";
                    state.byClass.status = "idle";
                    state.byClass.data = action.payload;
                }
            })
            .addCase(getByClass.rejected, (state, action) => {
                if (state.byClass.processing === action.meta.requestId) {
                    state.byClass.processing = "";
                    state.byClass.status = "error";
                    state.byClass.error = action.payload;
                    state.byClass.data = [];
                }
            })

            // Get by staff
            .addCase(getByStaff.pending, (state, action) => {
                state.byStaff.processing = action.meta.requestId;
                state.byStaff.status = "loading";
            })
            .addCase(getByStaff.fulfilled, (state, action) => {
                if (state.byStaff.processing === action.meta.requestId) {
                    state.byStaff.processing = "";
                    state.byStaff.status = "idle";
                    state.byStaff.data = action.payload;
                }
            })
            .addCase(getByStaff.rejected, (state, action) => {
                if (state.byStaff.processing === action.meta.requestId) {
                    state.byStaff.processing = "";

                    state.byStaff.status = "error";
                    state.byStaff.error = action.payload;
                    state.byStaff.data = [];
                }
            })

            // Get by tutor
            .addCase(getByTutor.pending, (state, action) => {
                state.byTutorGroup.processing = action.meta.requestId;
                state.byTutorGroup.status = "loading";
            })
            .addCase(getByTutor.fulfilled, (state, action) => {
                if (state.byTutorGroup.processing === action.meta.requestId) {
                    state.byTutorGroup.processing = "";
                    state.byTutorGroup.status = "idle";
                    state.byTutorGroup.data = action.payload;
                }
            })
            .addCase(getByTutor.rejected, (state, action) => {
                if (state.byTutorGroup.processing === action.meta.requestId) {
                    state.byTutorGroup.processing = "";
                    state.byTutorGroup.status = "error";
                    state.byTutorGroup.error = action.payload;
                    state.byTutorGroup.data = [];
                }
            })

            // Get by ethnicity
            .addCase(getByEthnicity.pending, (state, action) => {
                state.byEthnicity.processing = action.meta.requestId;
                state.byEthnicity.status = "loading";
            })
            .addCase(getByEthnicity.fulfilled, (state, action) => {
                if (state.byEthnicity.processing === action.meta.requestId) {
                    state.byEthnicity.processing = "";
                    state.byEthnicity.status = "idle";
                    state.byEthnicity.data = action.payload;
                }
            })
            .addCase(getByEthnicity.rejected, (state, action) => {
                if (state.byEthnicity.processing === action.meta.requestId) {
                    state.byEthnicity.processing = "";
                    state.byEthnicity.status = "error";
                    state.byEthnicity.error = action.payload;
                    state.byEthnicity.data = [];
                }
            })

            // Get by ethnicity rate
            .addCase(getByEthnicityRate.pending, (state, action) => {
                state.byEthnicityRate.processing = action.meta.requestId;
                state.byEthnicityRate.status = "loading";
            })
            .addCase(getByEthnicityRate.fulfilled, (state, action) => {
                if (state.byEthnicityRate.processing === action.meta.requestId) {
                    state.byEthnicityRate.processing = "";
                    state.byEthnicityRate.status = "idle";
                    state.byEthnicityRate.data = action.payload;
                }
            })
            .addCase(getByEthnicityRate.rejected, (state, action) => {
                if (state.byEthnicityRate.processing === action.meta.requestId) {
                    state.byEthnicityRate.processing = "";
                    state.byEthnicityRate.status = "error";
                    state.byEthnicityRate.error = action.payload;
                    state.byEthnicityRate.data = [];
                }
            })

            // Get by week
            .addCase(getByWeek.pending, (state, action) => {
                state.byWeek.processing = action.meta.requestId;
                state.byWeek.status = "loading";
            })
            .addCase(getByWeek.fulfilled, (state, action) => {
                if (state.byWeek.processing === action.meta.requestId) {
                    state.byWeek.processing = "";
                    state.byWeek.status = "idle";
                    state.byWeek.data = action.payload;
                }
            })
            .addCase(getByWeek.rejected, (state, action) => {
                if (state.byWeek.processing === action.meta.requestId) {
                    state.byWeek.processing = "";
                    state.byWeek.status = "error";
                    state.byWeek.error = action.payload;
                    state.byWeek.data = [];
                }
            })

            // Get behaviour and achievement by week
            .addCase(getBehaviourAndAchievementByWeek.pending, (state, action) => {
                state.byWeek.processing = action.meta.requestId;
                state.byWeek.status = "loading";
            })
            .addCase(getBehaviourAndAchievementByWeek.fulfilled, (state, action) => {
                if (state.byWeek.processing === action.meta.requestId) {
                    state.byWeek.processing = "";
                    state.byWeek.status = "idle";
                    state.byWeek.data = action.payload;
                }
            })
            .addCase(getBehaviourAndAchievementByWeek.rejected, (state, action) => {
                if (state.byWeek.processing === action.meta.requestId) {
                    state.byWeek.processing = "";
                    state.byWeek.status = "error";
                    state.byWeek.error = action.payload;
                    state.byWeek.data = [];
                }
            })

            // Get by student type
            .addCase(getByStudentType.pending, (state, action) => {
                state.byStudentType.processing = action.meta.requestId;
                state.byStudentType.status = "loading";
            })
            .addCase(getByStudentType.fulfilled, (state, action) => {
                if (state.byStudentType.processing === action.meta.requestId) {
                    state.byStudentType.processing = "";
                    state.byStudentType.status = "idle";
                    state.byStudentType.data = action.payload;
                }
            })
            .addCase(getByStudentType.rejected, (state, action) => {
                if (state.byStudentType.processing === action.meta.requestId) {
                    state.byStudentType.processing = "";
                    state.byStudentType.status = "error";
                    state.byStudentType.error = action.payload;
                    state.byStudentType.data = [];
                }
            })

            // Get attendance
            .addCase(getAttendance.pending, (state, action) => {
                state.attendance.processing = action.meta.requestId;
                state.attendance.status = "loading";
            })
            .addCase(getAttendance.fulfilled, (state, action) => {
                if (state.attendance.processing === action.meta.requestId) {
                    state.attendance.processing = "";
                    state.attendance.status = "idle";
                    state.attendance.data = action.payload;
                }
            })
            .addCase(getAttendance.rejected, (state, action) => {
                if (state.attendance.processing === action.meta.requestId) {
                    state.attendance.processing = "";
                    state.attendance.status = "error";
                    state.attendance.error = action.payload;
                    state.attendance.data = [];
                }
            })
            .addCase(getBehaviourAndAchievementByDayOfWeek.pending, (state, action) => {
                state.byDayOFWeek.processing = action.meta.requestId;
                state.byDayOFWeek.status = "loading";
            })
            .addCase(getBehaviourAndAchievementByDayOfWeek.fulfilled, (state, action) => {
                if (state.byDayOFWeek.processing === action.meta.requestId) {
                    state.byDayOFWeek.processing = "";
                    state.byDayOFWeek.status = "idle";
                    state.byDayOFWeek.data = action.payload;
                }
            })
            .addCase(getBehaviourAndAchievementByDayOfWeek.rejected, (state, action) => {
                if (state.byDayOFWeek.processing === action.meta.requestId) {
                    state.byDayOFWeek.processing = "";
                    state.byDayOFWeek.status = "error";
                    state.byDayOFWeek.error = action.payload;
                    state.byDayOFWeek.data = [];
                }
            })
            .addCase(getAttendancePc.pending, (state, action) => {
                state.attendancePc.processing = action.meta.requestId;
                state.attendancePc.status = "loading";
            })
            .addCase(getAttendancePc.fulfilled, (state, action) => {
                if (state.attendancePc.processing === action.meta.requestId) {
                    state.attendancePc.processing = "";
                    state.attendancePc.status = "idle";
                    state.attendancePc.data = action.payload[0].data;
                }
            })
            .addCase(getAttendancePc.rejected, (state, action) => {
                if (state.attendancePc.processing === action.meta.requestId) {
                    state.attendancePc.processing = "";
                    state.attendancePc.status = "error";
                    state.attendancePc.error = action.payload;
                    state.attendancePc.data = [];
                }
            });
    },
});

export default chartsSlice.reducer;
