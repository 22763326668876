import React, { useEffect } from "react";
import { PieChart } from "../charts/PieChart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Digits } from "../charts/Digits";
import { LineChart } from "../charts/LineChart";
import { BarChart } from "../charts/BarChart";
import {
    getByType,
    getPointsSummary,
    getBehaviourAndAchievementByWeek,
    getBehaviourAndAchievementByMonth,
    getBehaviourAndAchievementByTerm,
    getByStudentType,
    getIncidentsSummary,
    getBySubject,
    getByClass,
    getByTutor,
    getByStaff,
    getBehaviourAndAchievementByDayOfWeek,
    getAttendancePc,
} from "../../store/dashboard/chartsSlice";
import { Loader } from "../general/Loader";

export const DashboardOverview = () => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.dash.filters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);

    // Dashboard data
    const byTypeData = useSelector((state) => state.charts.byType.data);
    const pointsData = useSelector((state) => state.charts.pointsSummary.data);
    const incidentsData = useSelector((state) => state.charts.incidentsSummary.data);
    const monthData = useSelector((state) => state.charts.allByMonth.data);
    const termData = useSelector((state) => state.charts.allByTerm.data);
    const classData = useSelector((state) => state.charts.byClass.data);
    const tutorGroupData = useSelector((state) => state.charts.byTutorGroup.data);
    const staffData = useSelector((state) => state.charts.byStaff.data);
    const subjectData = useSelector((state) => state.charts.bySubject.data);
    const studentType = useSelector((state) => state.charts.byStudentType.data);
    const weekData = useSelector((state) => state.charts.byWeek.data);
    const dayofweekdata = useSelector((state) => state.charts.byDayOFWeek.data);
    const attendancepcdata = useSelector((state) => state.charts.attendancePc.data);

    const byTypeStatus = useSelector((state) => state.charts.byType.status);
    const pointsStatus = useSelector((state) => state.charts.pointsSummary.status);
    const incidentsstatus = useSelector((state) => state.charts.incidentsSummary.status);
    const monthstatus = useSelector((state) => state.charts.allByMonth.status);
    const termstatus = useSelector((state) => state.charts.allByTerm.status);
    const classstatus = useSelector((state) => state.charts.byClass.status);
    const tutorGroupstatus = useSelector((state) => state.charts.byTutorGroup.status);
    const staffstatus = useSelector((state) => state.charts.byStaff.status);
    const subjectstatus = useSelector((state) => state.charts.bySubject.status);
    const studentTypestatus = useSelector((state) => state.charts.byStudentType.status);
    const weekstatus = useSelector((state) => state.charts.byWeek.status);
    const dayofweekstatus = useSelector((state) => state.charts.byDayOFWeek.status);
    const attendancepcstatus = useSelector((state) => state.charts.attendancePc.status);

    const dashloadstatus = useSelector((state) => state.dash.loadStatus);

    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getByType({ include: [...filters, ...hiddenFilters], exclude: excludeFilters })); //{ key: "conducttype", value: "Behaviour" }
        dispatch(getPointsSummary({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getIncidentsSummary({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getBehaviourAndAchievementByMonth({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getBehaviourAndAchievementByTerm({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getBySubject({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getBehaviourAndAchievementByWeek({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getByClass({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getByTutor({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getByStaff({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getByStudentType({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getBehaviourAndAchievementByDayOfWeek({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));
        dispatch(getAttendancePc({ include: [...filters, ...hiddenFilters], exclude: excludeFilters }));

        //dispatch(getAttendance({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        // dispatch(getByEthnicity({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
    }, [dispatch, filters, excludeFilters, hiddenFilters, dashloadstatus]);

    if (
        byTypeStatus === "loading" ||
        pointsStatus === "loading" ||
        incidentsstatus === "loading" ||
        monthstatus === "loading" ||
        termstatus === "loading" ||
        classstatus === "loading" ||
        tutorGroupstatus === "loading" ||
        staffstatus === "loading" ||
        subjectstatus === "loading" ||
        studentTypestatus === "loading" ||
        weekstatus === "loading" ||
        dayofweekstatus === "loading" ||
        attendancepcstatus === "loading"
    ) {
        return <Loader />;
    } else {
        return (
            <div className="dashboard-area row">
                <PieChart title="Conduct by type" filterkey="category" data={byTypeData} />
                <Digits title="Points" data={pointsData} />
                <Digits title="Incidents/Awards" data={incidentsData} />
                <Digits title="Attendance" data={attendancepcdata} />
                <BarChart title="Conduct by tutor group" filterkey="tutorgroupname" data={tutorGroupData} />
                <PieChart title="Conduct by subject" filterkey="subjectname" data={subjectData} />
                <PieChart title="Conduct by staff" filterkey="staffcode" data={staffData} />
                <PieChart title="Conduct by class" filterkey="class" data={classData} />
                <BarChart title="Conduct by student type" filterkey="studenttype" data={studentType} />
                <LineChart title="Conduct by month" filterkey="month" data={monthData} />
                <BarChart title="Conduct by term" filterkey="term" data={termData} />
                <LineChart title="Conduct by week" filterkey="week" data={weekData} />
                <BarChart title="Conduct by Day of Week" filterkey="DayOfWeek" data={dayofweekdata} />
            </div>
        );
    }
    //return (<div>Overview</div>);
};
