import { get } from "./api";

export const chartApi = {
    getByType: async (filter, exclude) => {
        let url = `chart/bytype`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getBySubject: async (filter, exclude) => {
        let url = `chart/bysubject`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByStaff: async (filter, exclude) => {
        let url = `chart/bystaff`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByClass: async (filter, exclude) => {
        let url = `chart/byclass`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByTutor: async (filter, exclude) => {
        let url = `chart/bytutor`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByEthnicity: async (filter, exclude) => {
        let url = `chart/byethnicity`;
        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByEthnicityRate: async (filter, exclude) => {
        let url = `chart/byethnicityrate`;
        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByStudentType: async (filter, exclude) => {
        let url = `chart/bystudenttype`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getAttendance: async (filter, exclude) => {
        let url = `chart/attendance`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByWeek: async (filter, exclude) => {
        let url = `chart/byweek`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getAllByWeek: async (filter, exclude) => {
        let url = `chart/allbyweek`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getPoints: async (filter, exclude) => {
        let url = `chart/points`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getIncidents: async (filter, exclude) => {
        let url = `chart/incidents`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getPointsSummary: async (filter, exclude) => {
        let url = `chart/pointssummary`;

        return get(url, "json", { filter, exclude }).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getIncidentsSummary: async (filter, exclude) => {
        let url = `chart/incidentssummary`;

        return get(url, "json", { filter, exclude }).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getConductByMonth: async (filter, exclude) => {
        let url = `chart/bymonth`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getBehaviourAndAchievementByMonth: async (filter, exclude) => {
        let url = `chart/allbymonth`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getConductByTerm: async (filter, exclude) => {
        let url = `chart/byterm`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getBehaviourAndAchievementByTerm: async (filter, exclude) => {
        let url = `chart/allbyterm`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getStudentConduct: async (filter, exclude) => {
        let url = `chart/studentconduct`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getStudentConductTable: async (filter, exclude) => {
        let url = `chart/conducttable`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getBehaviourAndAchievementByDayOfWeek: async (filter, exclude) => {
        let url = `chart/allbydayofweek`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getAttendancePc: async (filter, exclude) => {
        let url = `chart/attendancepc`;

        return get(url, "json", { filter , exclude}).then((res) => {
            if (res) {
                return res;
            }
        });
    }
};
